<template>
    <div id="nav">
        <div class="left">
            <div class="top">
                <span>绿水青山</span>
                <span>相拥碳问</span>
            </div>
            <div class="bottom">
                <el-button v-show="!token" size="mini" class="login_register">登录/注册</el-button>
                <el-button size="mini" class="download_app" @click="downloadApp">下载APP</el-button>
            </div>
        </div>
        <div class="right">
            <div class="img bird">
                <img width="40px" height="40px" src="../../../assets/images/bird.png" alt="bird" />
            </div>
            <div class="img earth">
                <img width="80px" height="80px" src="../../../assets/images/earth.png" alt="bird" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Nav",
    data() {
        return {};
    },
    activated () {
        console.log('Nav', this.token);
    },
    computed: {
        token: function(){
            return this.$store.state.token;
        }
    },
    methods:{
      async  downloadApp()
        {
            let confirmPromise = new Promise((resolve, reject) => {
                this.$confirm("确定下载App吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    confirmButtonClass: "butText",
                    type: "info",
                    customClass: 'my-custom-confirm',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                }).then(() => {
                    // console.log('confirm');
                    var a = document.createElement('a');
                    a.href="http://47.104.68.37:13010/apk/CarbonApp-V1.0.0-release.apk";
                    a.download="双碳app";
                    a.style.display = 'none' // 隐藏a标签
                    document.body.appendChild(a) // 将a标签追加到文档对象中
                     a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                     a.remove(); // 一次性的，用完就删除a标签
                    resolve(true);
                }).catch(error => {
                    resolve(false);
                })
            });
            let confirm = await confirmPromise;
            if (!confirm) return;
        }
    }
};
</script>
<style lang="less">
#nav {
    height: 120px;
    /* padding: 3px calc( 50% - 480px ) 0; */
    padding: 0 calc(50% - 600px) 0;
    // margin: 0 0 24px;
    background: linear-gradient(205.828542483196deg,
            rgba(32, 162, 243, 1) 44%,
            rgba(106, 205, 108, 1) 52%);
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &>.left {
        width: 50%;
        padding: 0 0 0 118px;
        align-self: stretch;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        &>.top {
            font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 20px;
            color: #ffffff;
            padding: 23px 0 0;

            &>span {
                &+span {
                    padding: 0 0 0 20px;
                }
            }
        }

        &>.bottom {
            padding: 0 0 22px;

            &>.el-button {
                font-family: "微软雅黑", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: #ffffff;
                border: none;
                width: 120px;
                height: 31px;
                border-radius: 10px;

                &:first-child {
                    background-color: #ff6700;
                }

                &:last-child {
                    background-color: #045df3;
                    margin: 0 0 0 40px;
                }
            }
        }
    }

    &>.right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        padding: 0 0 0 110px;

        &>.bird {
            padding: 17px 0 0;
        }

        &>.earth {
            padding: 24px 0 0;
        }
    }

    // &.market {
    //   & > .icon {
    //     border: 10px solid transparent;
    //     background-image: url("../../../assets/images/green/u88.svg");
    //   }
    // }
    // &.travel {
    //   & > .icon {
    //     background-image: url("../../../assets/images/green/u89.svg");
    //   }
    // }
    // &.peak {
    //   & > .icon {
    //     border: 7px solid transparent;
    //     background-image: url("../../../assets/images/green/u90.svg");
    //   }
    // }
    // &.balance {
    //   & > .icon {
    //     border: 7px solid transparent;
    //     background-image: url("../../../assets/images/green/u91.svg");
    //   }
    // }
}
</style>