<template>
    <div id="basicLayout">
        <Header></Header>
        <!-- v-if="['Home', 'Research'].includes(routeName) && !token" -->
        <Nav v-if="['Home'].includes(routeName)"></Nav>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
        <Footer v-if="routeName != 'Knowledge'"></Footer>
    </div>
</template>
<script>
    import Header from '@/components/Header/index.vue';
    // import Nav from './components/Nav.vue';
    import Nav from '../../../views/Home/components/Nav.vue';
    import Footer from '@/components/Footer';
    export default {
        name: 'BasicLayout',
        components: {
            Header,
            Nav,
            Footer
        },
        computed: {
            routeName: function(){
                return this.$route.name;
            },
            token: function(){
                return this.$store.state.token;
            }
        },
    }
</script>
<style lang="less">
    #basicLayout{
        min-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        padding: 60px 0 0;
    }
</style>