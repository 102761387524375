<template>
    <div id="header">
        <div class="logo" @click="handleRoute('/home')">
            <img width="70px" height="37px" src="../../assets/images/u75.png" alt="logo" />
        </div>
        <div class="text-wrapper">
            <div class="recommend text" @click="handleRoute('/home')">推荐</div>
            <div class="market text" @click="handleRoute('/candlestick', { all: 1 })">
                <span class="name">行情</span>
                <!-- <span class="triangle"></span> -->
            </div>
            <!-- <template v-if="!!token"> -->
                <div class="group text" @click="handleRoute('/research')">碳研社</div>
                <!-- @click="handleRoute('/knowledge')" -->
                <div class="knowledge text" @click="handleRoute('/knowledge')">碳知识</div>
                <div class="tool text" @click="handleRoute('/tool')">碳工具</div>
            <!-- </template> -->
        </div>
        
        <div class="search">
            <el-input
                v-model="keyword"
                placeholder="搜索"
                size="small"
                @input="handleInput"
                @keyup.enter.native="handleSearch"
            >
            <!-- @input="handleInput"
                @keyup.enter.native="handleSearch" -->
                <i class="el-icon-search el-input__icon" slot="suffix" @click="handleSearch"></i>
            </el-input>
        </div>
        <!-- <div class="download text">下载App</div> -->
        <div v-if="!userInfo.id" class="login-register">
            <!-- <span class="user"></span> -->
            <div class="login button" @click="handleOpenLogin('login')">登录</div>
            <div class="split"></div>
            <div class="register button" @click="handleOpenLogin('register')">注册</div>
        </div>
        <div v-else class="user-info">
            <div class="alert">
                <alert />
            </div>
            <div class="user" @click="handleDropdownChange" @mouseleave="handleMouseLeaveLogo">
                <userLogoComponent width="22" height="22" :disabled-hover="true" :user="{ avatar: userInfo.avatar }" />
                <div class="user-name">
                    {{ userInfo.nickname }}
                </div>
                <div :class="['user-dropdown', active ? 'active' : '']" @click.stop @mouseenter="handleMouseEnterLogo" @mousedown="handleMouseLeaveLogo">
                    <div class="user-dropdown-user" @click.stop="handleViewMine">
                        <userLogoComponent width="24" height="24" :disabled-hover="true" :user="{ avatar: userInfo.avatar }" />
                        <span class="user-name">{{ userInfo.nickname }}</span>
                    </div>
                    <div class="produce" @click="handleProduce">
                        <userEdit />
                        <span>创作者中心</span>
                    </div>
                    <div class="logout" @click="handleLogout">
                        <logout />
                        <span>退出</span>
                    </div>
                </div>
            </div>
            <div class="publish-post" @mouseenter="handlePublishPostChange(true)" @mouseleave="handlePublishPostChange(false)">
                <el-button>发帖</el-button>
                <div v-show="showPublishDropdown" class="post-dropdown">
                    <div class="triangle"></div>
                    <div class="post-dropdown-item-wrapper">
                        <div class="post-dropdown-item" @click="handleWrite">发长文</div>
                    </div>
                    
                </div>
            </div>
            <!-- <el-dropdown class="publish-post" trigger="hover" :show-timeout="0" :hide-timeout="0" placement="bottom">
                <span class="el-dropdown-link">
                    <el-button>发帖</el-button>
                </span>
                <el-dropdown-menu slot="dropdown" class="user-publish-dropdown">
                    <el-dropdown-item>发长文</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->

        </div>
    </div>
</template>
<script>
import { alert, logout, userEdit } from '../../core/icon';
import userLogoComponent from '../../components/UserLogo/index.vue';
export default {
    name: "Header",
    components: {
        alert,
        logout,
        userEdit,
        userLogoComponent,
    },
    data() {
        return {
            keyword: "",
            timeout: null,
            dialogVisible: false,
            active: false,
            timeout: null,
            showPublishDropdown: false,
        };
    },
    activated () {
        if (this.$route.query.keyword){
            this.keyword = this.$route.query.keyword;
        }
    },
    computed: {
        token(){
            return this.$store.state.token;
        },
        userInfo(){
            return this.$store.state.userInfo;
        }
    },
    methods: {
        handleInput(val) {
            // console.log("handleInput", val, this.keyword);
            if (this.timeout) window.clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.handleSearch();
                this.timeout = null;
            }, 1000);
        },
        handleSearch() {
            if (this.$route.path !== "/home" && this.$route.path !== "/") {
                let text = this.$router.resolve({
                    path: "/home",
                    query: {
                        keyword: this.keyword,
                    },
                });
                // console.log("text", text);
                // 打开一个新的页面
                window.open(text.href, "_blank");
                return;
            } else {
                this.$router.push({
                    // path: "/home",
                    path: this.$route.path,
                    query: {
                        keyword: this.keyword,
                    },
                });
                this.$nextTick(() => {
                    this.$EventBus.$emit("search", this.keyword);
                })
                // this.$EventBus.$emit("search", this.keyword);
            }
            // this.$EventBus.$emit("search", this.keyword);
        },
        handleRoute(path, query){
            if (path === '/home'){
                this.$router.push({
                    path,
                });
            } else {
                let text = this.$router.resolve({
                    path,
                    query: query || {}
                });
                // console.log("text", text.href);
                // 打开一个新的页面
                window.open(text.href, "_blank");
            }
        },
        handleDropdownChange(){
            this.active = !this.active;
            if (this.timeout) window.clearTimeout(this.timeout);
        },
        handleMouseEnterLogo(){
            if (this.timeout) window.clearTimeout(this.timeout);
            this.active = true;
        },
        handleMouseLeaveLogo(){
            // return
            if (this.timeout) window.clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.active = false;
            }, 500)
            
            // setTimeout(() => {
            //     this.active = false;
            // }, 1000)
        },
        handleOpenLogin(type){
            this.$login.show({
                type
            });
        },
        handleCloseLogin(){
            // console.log('handleCloseLogin, login, header');
            this.active = false;
            this.showPublishDropdown = false;
            this.dialogVisible = false;
        },
        handleLogout(){
            this.$store.commit('setToken', '');
            this.$store.commit('setUserInfo', { id: '' });
            localStorage.removeItem('token', '');
            
        },
        handlePublishPostChange(showPublishDropdown){
            this.showPublishDropdown = showPublishDropdown;
        },
        handleProduce(){
            let route = this.$router.resolve({
                path: '/produce/write',
            });
            window.open(route.href, '_blank');
        },
        handleWrite(){
            let route = this.$router.resolve({
                path: '/produce/write',
            });
            window.open(route.href, '_blank');
        },
        handleViewMine(){
            let route = this.$router.resolve({
                path: `/u/${ this.userInfo.id }`,
                // query: {
                //     id: encodeURIComponent(encodeURIComponent(this.userInfo.id))
                // }
            });
            window.open(route.href, '_blank');
        }
    },
};
</script>
<style lang="less">
@import "./index.less";
</style>

