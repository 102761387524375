<template>
    <div id="footer" :style="{ 'backgroundColor': route == '/tool' ? 'rgb(240, 243, 245)' : '' }">
        <div class="company">
            <!-- <div class="info">
        <div class="english">FonNon</div>
        <div class="name">泛能智慧（武汉）科技有限公司</div>
        <div class="address">
          武汉：武汉市东湖新技术开发区现代光谷世贸中心E栋8层
        </div>
        <div class="mail">邮箱：zhuyc@labbol.com</div>
      </div>
      <div class="question">常见问题</div>
      <div class="about">关于我们</div> -->
            <div class="about">关于碳问</div>
            <div class="protocol">碳问服务协议</div>
            <div class="policy">碳问隐私政策</div>
            <div class="contact">联系我们</div>
            <div class="service">
                <img width="35px" height="35px" src="../../assets/images/service.png" alt="" />
                <span>在线客服</span>
            </div>
        </div>
        <div class="copyright">
            <span>
                Copyright @2022 Labbol&nbsp;&nbsp;All Rights
                Reserved&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <a href="https://beian.miit.gov.cn" target="_blank">备案号：京ICP备2022014052号-2</a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040879" target="_blank">
                <img src="../../assets/images/emblem.png" alt="">
                京公网安备 11010802040879号</a>
        </div>
    </div>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {
            background: '',
        };
    },
    computed: {
        route: function(){
            // console.log(this.$route.path);
            return this.$route.path;
        }
    },
};
</script>
<style lang="less">
@import "./index.less";
</style>